$(document).ready( function() {
	$('#startCarousel').carousel({
		interval:   400000000
	});

$('slid.bs.carousel', function(e) {
        var selector = '.nav li';
    $(selector).on('click', function(){
        $(selector).removeClass('active');
        $(this).addClass('active');
    });
});

	// hide #back-top first
	$("#back-top").hide();

	// fade in #back-top
	$(function () {
		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				$('#back-top').fadeIn();
			} else {
				$('#back-top').fadeOut();
			}
		});

		// scroll body to 0px on click
		$('#back-top a').click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 800);
			return false;
		});
	});
	// search box
	$('.icon-magnifier').on('click', function () {
		$('.search-word').toggleClass("search-word-open");
		$('.search-bar').toggleClass("search-bar-open");
		$('.search-icon').toggleClass("search-icon-open");
		$('.icon-search_res').toggleClass("icon-search_res-open");
	});

	$('.showlogin').on('click', function () {
		$('.login-box').toggleClass("login-box-open");
		$('.login-close').toggleClass("login-close-open");
	});
	$('.login-close').on('click', function () {
		$('.login-box').toggleClass("login-box-open");
		$('.login-close').toggleClass("login-close-open");
	});
});

var selector = 'li.dropdown a';

$(selector).hover(function(){

	$(this).addClass('active');
});
