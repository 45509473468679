$(document).ready(function() {
    if (typeof window.galleries !== 'undefined') {
        $.each(window.galleries, function(index, gallery) {
            var big = $('#' + gallery + 'big'),
                small = $('#' + gallery + 'small'),
                items = big.find('.item').length;
            big.owlCarousel({
                singleItem: true,
                slideSpeed: 1000,
                navigation: true,
                navigationText: [
                    "<span class='glyphicon glyphicon-chevron-left icon-left_res' aria-hidden='true'></span>",
                    "<span class='glyphicon glyphicon-chevron-left icon-right_res' aria-hidden='true'></span>"
                ],
                pagination: false,
                afterAction: function(el) {
                    var current = this.currentItem;
                    small
                        .find(".owl-item")
                        .removeClass("synced")
                        .eq(current)
                        .addClass("synced");
                    if (small.data("owlCarousel") !== undefined) {
                        center(current, small)
                    }
                },
                responsiveRefreshRate: 200
            });
            small.owlCarousel({
                items: items,
                itemsDesktop: [1199, 4],
                itemsDesktopSmall: [979, 4],
                itemsTablet: [768, 4],
                itemsMobile: [479, 3],
                pagination: false,
                responsiveRefreshRate: 100,
                afterInit: function(el) {
                    el.find(".owl-item").eq(0).addClass("synced");
                }
            });
            small.on("click", ".owl-item", function(e) {
                e.preventDefault();
                var number = $(this).data("owlItem");
                big.trigger("owl.goTo", number);
            });
        });
    }

    function center(number, small){
        var sync2visible = small.data("owlCarousel").owl.visibleItems;
        var num = number;
        var found = false;
        for(var i in sync2visible){
            if(num === sync2visible[i]){
                var found = true;
            }
        }

        if(found===false){
            if(num>sync2visible[sync2visible.length-1]){
                small.trigger("owl.goTo", num - sync2visible.length+2)
            }else{
                if(num - 1 === -1){
                    num = 0;
                }
                small.trigger("owl.goTo", num);
            }
        } else if(num === sync2visible[sync2visible.length-1]){
            small.trigger("owl.goTo", sync2visible[1])
        } else if(num === sync2visible[0]){
            small.trigger("owl.goTo", num-1)
        }

    }

});
